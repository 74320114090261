<template>
    <div class="font-poppin">
        <div class="filter-header">
            <div class="fs-14 font-weight-bold ml-4">Filters (<span style="color:#ffba00 !important;">{{totalFilters}}</span>)</div>
            <div class="pointer">
                <span @click="update()">
                    <img class="filter-Images" src="/static/images/filter_check_blue.svg" style="width: 35px;" alt="arrow">
                </span>
                <span @click="resetFilter()">
                    <img class="filter-Images" src="https://cdn.digicollect.com/cdn/scmnew/images/scm/ResetBgBlue.svg" alt="reset">
                </span>
                <span @click="closeFilters()">
                    <img class="filter-Images" src="https://cdn.digicollect.com/cdn/scmnew/images/scm/CloseBgBlue.svg" alt="close">
                </span>
            </div>
        </div>
        <div class="container filter-content">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">    
                    <div class="group-setup mt-6">
                        <div class="fs-14 text-secondary font-weight-bold">Department Name</div>
                        <multiselect  :limit="1" :limit-text="limitDeprtmentName" :show-labels="false" 
                        v-model="temp_department_name" style="background-color: #e6ecff !important;" 
                        :blockKeys="['Delete']" @remove="removeDepartmentName" :loading="isLoading"
                        @open="getListViewData" placeholder="Select Department Head" 
                        class="diginew-multiselect diginew-multiselect-underline mt-2" :options="department_name_options" 
                        :multiple="true" :searchable="true" :internal-search="true" 
                        :clear-on-select="false" :close-on-select="true" :options-limit="3000" 
                        :max-height="200" :show-no-results="true" :hide-selected="false" >

                        <!-- <multiselect :limit="1" :loading="isLoading" :limit-text="limitDeprtmentName" 
                        :show-labels="false" v-model="temp_department_name" style="background-color: #e6ecff !important;" 
                        track-by="id" :blockKeys="['Delete']" @remove="removeDepartmentName" 
                        placeholder="Select Department Name" class="diginew-multiselect diginew-multiselect-underline mt-2" 
                        :options="department_name_options" @search-change="getListViewData" @open="getListViewData" :multiple="true" 
                        :searchable="true" :internal-search="true" :clear-on-select="false" :close-on-select="true" 
                        :options-limit="3000" :max-height="200" :show-no-results="true" :hide-selected="false" > -->
                            <template slot="tag" slot-scope="{ option }">
                                <span class="custom__tag">
                                <span class="filterSelectedName">{{ option }}</span>
                                    <span class="custom__remove pointer" @click="removeDepartmentName(option)">
                                        <i class="icon icon-close fs-16 text-white"></i>
                                    </span>
                                </span>
                            </template>
                            <template slot="afterList">
                                <div v-observe-visibility="reachedEndOfList" />
                            </template>
                            <template slot="noResult">
                                <div class="text-secondary text-center fw-600">No Results Found</div>
                            </template>
                        </multiselect>
                    </div>
                </div>
                <!-- <div class="col-lg-6 col-md-6 col-sm-12">    
                    <div class="group-setup mt-6">
                        <div class="fs-14 text-secondary font-weight-bold">Code</div>
                        <multiselect :limit="1" :loading="isLoading" 
                            :limit-text="limitCode" :show-labels="false" 
                            v-model="temp_code" style="background-color: #e6ecff !important;" 
                            track-by="id" label="code" :blockKeys="['Delete']" @remove="removeCode" 
                            placeholder="Select Code" class="diginew-multiselect diginew-multiselect-underline mt-2" 
                            :options="department_name_options" @search-change="getListViewData" 
                            :multiple="true" :searchable="true" :internal-search="true" 
                            :clear-on-select="false" :close-on-select="true" :options-limit="3000" 
                            :max-height="200" :show-no-results="true" :hide-selected="false" 
                        >
                            <template slot="tag" slot-scope="{ option }">
                                <span class="custom__tag">
                                <span class="filterSelectedName">{{ option.code }}</span>
                                    <span class="custom__remove pointer" @click="removeCode(option)">
                                        <i class="icon icon-close fs-16 text-white"></i>
                                    </span>
                                </span>
                            </template>
                            <template slot="afterList">
                                <div v-observe-visibility="reachedEndOfList" />
                            </template>
                            <template slot="noResult">
                                <div class="text-secondary text-center fw-600">No Results Found</div>
                            </template>
                        </multiselect>
                    </div>
                </div> -->
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="group-setup mt-6">
                        <div class="fs-14 text-secondary font-weight-bold">Started On</div>
                        <date-picker class="profile-date-picker calendar-edit-per input-field-underline mt-2 pt-0 pb-0 pr-0 w-100" style="background-color: #e6ecff !important;" v-model="temp_started_on" value-type="format" lang="en" 
                        :clearable="false"
                        :editable="false"
                        :confirm="true" type="date" format="DD/MM/YYYY"></date-picker>
                    </div>
                </div>
            </div>     
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">   
                    <div class="group-setup mt-6">
                        <div class="fs-14 text-secondary font-weight-bold">Department Head</div>
                        <multiselect label="name" :limit="1" :limit-text="limitDepartmentHead" :show-labels="false" 
                        track-by="id" v-model="temp_department_head" style="background-color: #e6ecff !important;" 
                        :blockKeys="['Delete']" @remove="removeDepartmentHead" :loading="is_depart_head_loading"
                        @open="getDepartHeadOptions" placeholder="Select Department Head" 
                        class="diginew-multiselect diginew-multiselect-underline mt-2" :options="department_head_options" 
                        :multiple="true" :searchable="true" :internal-search="true" 
                        :clear-on-select="false" :close-on-select="true" :options-limit="3000" 
                        :max-height="200" :show-no-results="true" :hide-selected="false" >
                            <template slot="tag" slot-scope="{ option }">
                                <span class="custom__tag">
                                <span class="filterSelectedName">{{ option.name }}</span>
                                    <span class="custom__remove pointer" @click="removeDepartmentHead(option)">
                                        <i class="icon icon-close fs-16 text-white"></i>
                                    </span>
                                </span>
                            </template>
                            <template slot="afterList">
                                <div v-observe-visibility="reachedEndOfList" />
                            </template>
                            <template slot="noResult">
                                <div class="text-secondary text-center fw-600">No Results Found</div>
                            </template>
                        </multiselect>
                    </div>
                </div>    
                <div class="col-lg-6 col-md-6 col-sm-12">   
                    <div class="group-setup mt-6">
                        <div class="fs-14 text-secondary font-weight-bold">Business Units</div>
                        <multiselect :limit="1" :loading="is_bus_unit_loading" :limit-text="limitBusUnit" :show-labels="false" 
                        v-model="temp_business_unit" style="background-color: #e6ecff !important;" 
                        track-by="business_unit_id" label="business_unit_name" :blockKeys="['Delete']" @remove="removeBusinessUnit" 
                        @open="getBusinesUnitOptions" placeholder="Select Business Unit" 
                        class="diginew-multiselect diginew-multiselect-underline mt-2" :options="business_unit_options" 
                        :multiple="true" :searchable="true" :internal-search="true" :clear-on-select="false" 
                        :close-on-select="true" :options-limit="3000" :max-height="200" :show-no-results="true" :hide-selected="false" >
                            <template slot="tag" slot-scope="{ option }">
                                <span class="custom__tag">
                                <span class="filterSelectedName">{{ option.business_unit_name }}</span>
                                    <span class="custom__remove pointer" @click="removeBusinessUnit(option)">
                                        <i class="icon icon-close fs-16 text-white"></i>
                                    </span>
                                </span>
                            </template>
                            <template slot="afterList">
                                <div v-observe-visibility="reachedEndOfList" />
                            </template>
                            <template slot="noResult">
                                <div class="text-secondary text-center fw-600">No Results Found</div>
                            </template>
                        </multiselect>
                    </div>
                </div>
            </div>          
        </div>
    </div>    
</template>
<script>
import companies from '../mixins/companies';
export default {
    mixins:[companies],
    props:['showfilterData'],
    data(){
        return{
            temp_started_on: "",
            // temp_code: [],
            temp_department_head: [],
            temp_business_unit: [],
            department_head_options: [],
            // code_options: [],
            business_unit_options: [],
            temp_department_name: [],
            department_name_options: [],
            isLoading: false,
            isVisible: false,
            count: 0,
            skip: 0,
            limit: 10,
            search_key: '',
            scrollable: false,
            is_depart_head_loading: false,
            is_bus_unit_loading: false
        }
    },
    methods:{
        // limitCode(count) {
		// 	return `+ ${count} more`
        // },
        limitDepartmentHead(count) {
			return `+ ${count} more`
        },
        limitDeprtmentName(count) {
			return `+ ${count} more`
        },
        limitBusUnit(count) {
			return `+ ${count} more`
        },
        closeFilters(){
            this.hide_filters = false
            this.$emit('hideFilters')
        },
        resetFilter(){
            if(this.totalFilters > 0){
                this.temp_started_on = ''
                // this.temp_code = []
                this.temp_department_head = []
                this.temp_business_unit = [] 
                this.temp_department_name = []
                this.update()
            }
        },
        reachedEndOfList() {

        },
        async getBusinesUnitOptions() {
            this.is_bus_unit_loading = true;
            try {
                let response = await this.getDepartmentBusinessUnitDropdown(this.$route.params.company_id);
                if(response.status_id == 1) {
                    this.business_unit_options = response.response;
                }
                this.is_bus_unit_loading = false;
            }
            catch(err) {
            }
        },
        async getDepartHeadOptions() {
            this.is_depart_head_loading = true;
            try {
                let response = await this.getDepartmentHeadDropdown(this.$route.params.company_id);
                if(response.status_id == 1) {
                    this.department_head_options = response.response;
                }
                this.is_depart_head_loading = false;
            }
            catch(err) {
                console.log(err);
            }
        },
        async getListViewData() {
            this.isLoading = true;
            this.department_name_options = [];
            try {
                let response = await this.getDepartmentDropdown(this.$route.params.company_id);
                if(response.status_id == 1) {
                    this.department_name_options = response.response;
                }
                this.isLoading = false;
            } catch (err) {
                this.isLoading = false;
            }
        },
        update(){
            let started_on = this.temp_started_on
            // let code = this.temp_code.map(code => {return code.code})
            let department_head1 = this.temp_department_head
            let business_unit_id1 = this.temp_business_unit
            let department_head = this.temp_department_head.map(head => {return head.id})
            let business_unit_id = this.temp_business_unit.map(bus => {return bus.business_unit_id})
            let department_name = this.temp_department_name.map(depart => {return depart})
            let params = {
                started_on: started_on,
                department_head: department_head,
                business_unit_id: business_unit_id,
                department_name: department_name
            }
            let params1 = {
                started_on: started_on,
                department_head: department_head1,
                business_unit_id: business_unit_id1,
                department_name: department_name
            }
            let newData = {
                value:params,
                value1:params1
            }
            this.closeFilters();
            this.$emit('UpdateByFilter',newData);
        },
        // removeDob(option){
        //     this.temp_started_on.forEach((el,index)=>{
        //         if(el.toLowerCase() == option.toLowerCase()){
        //             this.temp_started_on.splice(index,1)
        //         }
        //     })
        // },
        // removeCode(option){
        //     this.temp_code.forEach((el,index)=>{
        //         if(el.code == option.code){
        //             this.temp_code.splice(index,1)
        //         }
        //     })
        // },
        removeDepartmentHead(option){
            this.temp_department_head.forEach((el,index)=>{
                if(el.id == option.id){
                    this.temp_department_head.splice(index,1)
                }
            })
        },
        removeBusinessUnit(option){
            this.temp_business_unit.forEach((el,index)=>{
                if(el.business_unit_id == option.business_unit_id){
                    this.temp_business_unit.splice(index,1)
                }
            })
        },
        removeDepartmentName(option){
            this.temp_department_name.forEach((el,index)=>{
                if(el == option){
                    this.temp_department_name.splice(index,1)
                }
            })
        },
    },
    async mounted(){
        this.temp_started_on = this.showfilterData.started_on ? this.showfilterData.started_on.split("-").reverse().join("/") : ''
        // this.temp_code = this.showfilterData.code
        this.temp_department_name = this.showfilterData.department_name
        console.log(this.temp_department_name);
        this.temp_department_head = this.showfilterData.department_head 
        console.log(this.temp_department_head);
        this.temp_business_unit = this.showfilterData.business_unit_id
        console.log(this.temp_business_unit);
        // EventBus.$on('removechip',params=>{
        //     if(params.key == 'code'){
        //         this.removeCode(params.value)
        //         this.update()
        //     }else if(params.key == 'country'){
        //         this.removeDepartmentHead(params.value)
        //         this.update()
        //     }else if(params.key == 'business_unit'){
        //         this.removeBusinessUnit(params.value)
        //         this.update()
        //     }
        // })
        // EventBus.$on('removeAllchip',() => {
        //     this.resetFilter()
        // })
    },
    destroyed(){
        // EventBus.$off('removechip')
        // EventBus.$off('removeAllchip')
    },
    computed:{
        totalFilters(){
            let date_length;
            if(this.temp_started_on == ''){
                date_length = 0
            }else{
                date_length = 1
            }
            return date_length + this.temp_department_head.length + this.temp_business_unit.length + this.temp_department_name.length;
        }    
    }
}
</script>
<style scoped>
.filter-content{
    max-height: 534px !important;
    overflow-x: visible;
    overflow-y: visible;
}
</style>